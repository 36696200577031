import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';

export const FooterLink: React.FC<{
  children: JSX.Element | string;
  to: string;
}> = ({ children, to }) => (
  <div className="d-inline-block me-2">
    <Link href={to} className="text-decoration-none ">
      {children}
    </Link>
  </div>
);

export const FooterLinks: React.FC = () => (
  <ul className={classNames('list-unstyled')}>
    <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
    <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
  </ul>
);

export default function Footer() {
  return (
    <div className="bg-light">
      <footer className="container py-5">
        {/* <FooterLinks /> */}
        <div>
          <a href="mailto:hi@whennot.com" className=" text-decoration-none text-body">
            hi@whennot.com
          </a>
        </div>
        <div>©{new Date().getFullYear()} WhenNOT.com</div>
        <div className="text-muted pt-5">
          <a href="https://hern.as" className=" text-decoration-none text-body">
            Made by Hernas.ee
          </a>
        </div>
      </footer>
    </div>
  );
}
