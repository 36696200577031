'use client';

import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Link from 'next/link';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons';
import HomeContainer from './components/HomeContainer';
import examplePic from '../../public/statics/images/example.png';
import whyPic from '../../public/statics/images/why-photo.png';

export default function IndexPage({
  pollAnswersNumber,
  exampleUUID
}: {
  pollAnswersNumber: number;
  exampleUUID: string;
}) {
  return (
    <HomeContainer>
      <Row className="align-items-center py-5">
        <Col xs={12} lg={6} className="lead">
          <h1 className="display-3 mb-0">Plan your next event</h1>
          <h2 className="display-6 mb-5">by asking when not</h2>
          <p>Find a date that will work for everyone:</p>
          <ul>
            <li>ski trip with friends</li>
            <li>company get together</li>
            <li>family vacation</li>
          </ul>
          <Link href="/poll" passHref legacyBehavior>
            <Button variant="primary" size="lg">
              <FontAwesomeIcon icon={faCalendarCheck} className="me-2" />
              {' Start Planning'}
            </Button>
          </Link>

          <p className="my-5">
            Quickly find the best dates that fit <strong>everybody</strong> by asking <strong>when not</strong>. <br />
            We have already helped {pollAnswersNumber} users, be one of them!
          </p>
        </Col>
        <Col xs={12} lg={6}>
          <Link href={`/poll/${exampleUUID}`} className="text-decoration-none ms-0 ms-md-4">
            <Image src={examplePic} alt="Picture of the example WhenNOT event" className="img-fluid rounded" />
            <p className="text-muted text-center mt-2">click to see example event</p>
          </Link>
        </Col>
        <Col className="d-flex justify-content-center align-items-center py-5" xs={12}>
          <a
            href="https://www.producthunt.com/posts/whennot-com?utm_souce=badge-whennot&#0045;com"
            target="_blank"
            rel="noreferrer"
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=452295&theme=light"
              alt="WhenNOT&#0046;com - Plan&#0032;your&#0032;next&#0032;event&#0032;by&#0032;asking&#0032;when&#0032;not | Product Hunt"
              style={{ width: 250, height: 54 }}
            />
          </a>
        </Col>
      </Row>
      <Row className="align-items-center pt-5">
        <Col xs={8} lg={6} className="mx-auto pe-lg-5 order-last order-lg-first">
          <Image
            src={whyPic}
            alt="Picture of diverse people trying to plan event on the calendar"
            className="img-fluid rounded"
          />
        </Col>
        <Col xs={12} lg={6} className="lead">
          <h2>Why did I build it?</h2>
          <p>I love creating products that solve people&apos;s needs.</p>
          <p>
            One day, my wife asked me if I knew of any tools that could help plan a week-long company getaway. The
            requirements were fairly straightforward:
          </p>
          <ul>
            <li>14 people</li>
            <li>A 7-day trip</li>
            <li>Sometime in the next 6 months</li>
          </ul>
          <p>
            They weren’t concerned about choosing the exact time yet; they just needed to know which dates would work
            for everyone—or put another way, <strong>when does it NOT work?</strong>
          </p>
          <p className="fw-bold">
            I searched but couldn’t find anything that met this specific need, so I decided to build it myself.
          </p>
          <p>
            The concept is simple: think of it as an inverse Doodle. You select a date range for the potential event and
            then ask your friends, family, or coworkers to mark the dates they are unavailable. This way, you can easily
            identify the best possible time slot.
          </p>
          <p>
            This approach allows flexibility in planning. For instance, if there’s only a 5-day stretch available, that
            could work too.
          </p>
        </Col>
      </Row>
    </HomeContainer>
  );
}
