import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import NavbarComponent from './Navbar';
import Footer from './Footer';

export default function HomeContainer({ children }: React.PropsWithChildren<unknown>) {
  return (
    <div className="d-flex flex-column h-100">
      <NavbarComponent />
      <Container className="my-4 flex-grow-1">
        <Row>
          <Col>{children}</Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
